.modalForm {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 0 !important;
      }
    }
  }
}

.antdForm {
  .ant-form-item {
    margin-bottom: 20px !important;
  }
  .ant-form-item:last-child {
    margin-bottom: 5px !important;
  }
}