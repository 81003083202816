
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-blue-500 cursor-pointer;
  }
  table{
    border-color:#BB5E00;
  }
  table tr td{
    border-color:#BB5E00;

  }
  .border-none {
    border-style: none !important;
  }

  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-xl {
    font-size: 1.25rem !important;
  }
  .border-b-4 {
    border-bottom-width: 4px !important;
  }
  .border-double {
    border-style: double !important;
  }
  .h-0 {
    height: 0px !important;
  }
  .border-r-0 {
    border-right-width: 0px !important;
  }
  .border-0 {
    border-width: 0px !important;
  }
  .h-10 {
    height: 2.5rem !important;
  }
  .text-red-500 {
    color: rgb(239 68 68) !important;
  }
  .text-base {
    font-size: 1rem !important;
  }
  .text-lg {
    font-size: 1rem !important;
  }
  .text-2xl {
    font-size: 1.5rem !important;
  }
  .leading-10 {
    line-height: 2.5rem !important;
  }
  .border-2 {
    border-width: 2px !important;
  }
  .border-t {
    border-top-width: 1px !important;
  }
  .border-b-0  {
    border-bottom-width: 0px !important;
  }
  .bg-neutral-200  {
    background-color: rgb(229 229 229) !important;
  }
  .border-t-0  {
    border-top-width: 0px !important;
  }
  .text-justify  {
    text-align: justify !important;
  }
  .tracking-widest  {
    letter-spacing: 0.1em !important;
  }
  .font-bold  {
    font-weight: 700 !important;
  }
  .border-y  {
    border-bottom-width: 1px !important;
  }
  .border-dashed  {
    border-style: dashed !important;
  }
  .text-gray-400  {
    color: rgb(156 163 175) !important;
  }
  .text-purple-900  {
    color: rgb(88 28 135) !important;
  }
  .border-t-2  {
    border-top-width: 2px !important;
  }
  .border-b-2  {
    border-bottom-width: 2px !important;
  }
  .border-orange-700 {
    border-color: rgb(194 65 12) !important;
  }
  .border-dotted {
    border-style: dotted !important;
  }
  .indent-12 {
    text-indent: 3rem !important;
  }
  .text-orange-500 {
    color: rgb(249 115 22) !important;
  }
  .border-l-2 {
    border-left-width: 2px !important;
  }
  .border-r-4 {
    border-right-width: 4px !important;
  }
  .border-r-2 {
    border-right-width: 2px !important;
  }
  
  .border-zinc-300 {
    border-color: rgb(212 212 216) !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .text-black {
    color: rgb(0 0 0) !important;
  }
  .h-5 {
    height: 1.25rem !important;
  }  
  .leading-4 {
    line-height: 1rem !important;
  }  
  .leading-8 {
    line-height: 2rem !important;
  }   
  .leading-6 {
    line-height: 1.5rem !important;
  }   
  .leading-10 {
    line-height: 2.5rem !important;
  } 
  .indent-9 {
    text-indent: 2.25rem !important;
  } 
  .border-dotted {
    border-style: dotted !important;
  }
  .border-slate-600 {
    border-color: rgb(71 85 105) !important;
  }

  .twd001 {text-align:justify;text-justify:distribute-all-lines;text-align-last:justify;padding-right:8px;padding-left:8px;}
  .twd002 {border-width:0 0 1px 0;}
  .twd003 {width:100%;margin:0 auto;text-align:left;text-indent:25px;font:normal 12px 宋体;color: #000000;line-height:28px;} 
  .twd004 {font-family:Arial, Helvetica, sans-serif;}   
  .twd005 {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
  .twd006 {border-top:double #BB5E00 3px;}
  .twd007 {border-right:red 2px solid;}
  .twd008 {padding:0 5px 0 0;}
  .twd009 {font-family: 黑体;}
  .twd010 {font-family:楷体_GB2312;}
  .twd011 {border-width:1px 0 1px 0;}
  .twd012 {border:solid #BB5E00;border-width:0 0px 1px 0;}
  .twd013 {border-right:solid red 1px;}
}

@layer components {
  .icon {
    @apply inline-block w-5 h-5 stroke-current text-yellow-600 align-text-bottom cursor-pointer;
  }

  code {
    @apply font-mono text-red-600;
  }

  .notice {
    @apply bg-blue-100  border-blue-300 text-blue-500 container m-auto mt-2 border px-4 py-2 rounded relative;

    .notice-error {
      @apply bg-red-100  border-red-400 text-red-700;
    }
  }

  .alert {
    @apply bg-red-100  border-red-300 text-red-500 container m-auto mt-2 border px-4 py-2 rounded relative;

    .alert-error {
      @apply bg-red-100  border-red-400 text-red-700;
    }
  }

  .btn {
    @apply inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;

    svg,
    .icon {
      @apply -ml-1 mr-2 h-5 w-5 text-gray-500;
    }

    &:active {
      @apply border-gray-300;
    }

    &-sm {
      @apply px-2 py-1 text-sm;
    }

    &-lg {
      @apply px-6 py-4 text-lg;
    }

    &-primary {
      @apply text-white bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500;

      &:active {
        @apply bg-blue-900 border-blue-900;
      }
    }

    &-danger {
      @apply bg-red-600 border-red-600 hover:bg-red-700  hover:border-red-700 text-white;
      &:active {
        @apply bg-red-900 border-red-900;
      }
    }
  }

  .btn-group {
    @apply inline-flex items-center justify-center border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;

    .btn {
      @apply relative inline-flex rounded-none border-0 shadow-none border-r border-gray-300;

      &:first-child {
        @apply rounded-l;
      }

      &:last-child {
        @apply rounded-r border-r-0;
      }
    }
  }

  select {
    @apply mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
  }

  .user-menu {
    @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50;

    &-item {
      @apply block px-4 py-2 text-sm text-gray-500;
    }

    &-link {
      @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100;
    }

    &-divider {
      @apply border-b border-gray-200;
    }
  }

  .container {
    @apply mx-auto;
  }

  .main-navbar {
    @apply bg-white shadow;

    .nav-menu {
      @apply hidden sm:ml-6 sm:flex sm:space-x-8;
    }

    .nav-item {
      @apply inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium;
      @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-500;

      &.active {
        @apply border-yellow-500 text-gray-500;
      }
    }
    .nav-item-disclosure {
      @apply block pl-3 pr-4 py-2 border-l-4 text-base font-medium;
      @apply border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700;

      &.active {
        @apply bg-yellow-50 border-yellow-500 text-yellow-700;
      }
    }
  }

  .form-group {
    @apply m-auto text-left mb-3;

    &.form-group-error {
      .ant-input {
        @apply border-red-300 ring-red-100 focus:ring-red-100;
      }

      .form-text {
        @apply hidden;
      }
    }
  }

  .form-label {
    @apply flex items-center mb-2;

    abbr {
      text-decoration: none;

      @apply text-red-400 ml-1 text-base pt-1 cursor-default;
    }
  }

  .form-error {
    @apply mt-2 text-sm  text-red-500;
  }

  .form-text {
    @apply mt-2 text-sm text-gray-400;
  }

  // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }

  input.form-control,
  textarea.form-control {
    @apply block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md;
  }

  .form-actions {
    @apply text-center sm:justify-center lg:justify-start flex md:justify-start md:space-x-3;

    .btn {
      min-width: 100px;
    }
  }

  footer {
    @apply container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col;
  }
}

.card {
  @apply bg-white border border-gray-200 shadow-sm sm:rounded-lg;

  .card-header {
    @apply px-4 py-5 sm:px-6 border-b border-gray-200;
  }

  .card-header-title {
    @apply text-lg leading-6 font-medium text-gray-900;
  }
  .card-header-subtitle {
    @apply mt-1 max-w-2xl text-sm text-gray-500;
  }

  .card-body {
    @apply px-4 py-5 sm:px-6;
  }

  .card-footer {
    @apply bg-gray-50 border-t border-gray-200 px-4 py-6 sm:px-6 sm:rounded-t-none sm:rounded-lg;
  }
}
