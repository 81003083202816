html {
  .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #40a9ff;
      border-color: #40a9ff;
      color: #fff;
    }
    &:active,
    &.active {
      background-color: #096dd9;
      border-color: #096dd9;
      color: #fff;
    }
    &:disabled,
    &.disabled {
      background-color: #d9d9d9;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  font-size: 14px;

  .ant-message {
    z-index: 999999;
    .ant-message-notice-content {
      @apply max-w-sm  bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden;

      .ant-message-custom-content {
        @apply inline-flex items-center;

        .anticon {
          top: 0 !important;
        }
      }

      &-success {
        @apply text-green-400;
      }
      &-info {
        @apply text-blue-400;
      }
      &-error {
        @apply text-red-400;
      }
      &-warning {
        @apply text-yellow-400;
      }
    }
  }

  .ant-input {
    @apply block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md;

    &:focus {
      @apply ring-blue-500 border-blue-500;
    }
  }

  .ant-input {
    @apply border w-full my-1 p-3 rounded-md text-sm appearance-none;
  }

  .ant-breadcrumb-separator {
    @apply text-gray-200;
  }

  .ant-row.ant-form-item {
    @apply mb-0;
  }

  .ant-tag {
    @apply mx-1;
  }

  .ant-dropdown-menu-title-content {
    @apply px-10;
  }

  .ant-input-group .ant-input {
    @apply mt-0;
  }

  .ant-list.ant-list-split {
    @apply bg-white shadow sm:rounded-md;
  }
  
  .ant-list.ant-list-split .ant-list-items {
    @apply divide-y divide-gray-200;
  }

  .ant-list.ant-list-split .ant-list-item {
    @apply px-4 py-4 sm:px-6;
  }

  .ant-tag .anticon {
    vertical-align: 0.025rem;
  }

  .ant-card {
    @apply mb-5;
  }

  .ant-list-item-action a {
    @apply text-blue-500;
  }

  .ant-table,.ant-btn {
    font-size: 14px;
  }

  .ant-list {
    @apply p-5;
  }

  // card form
  .ant-card {
    box-shadow: none !important;
  }

  .ant-card-none {
    .ant-card-body {
      @apply px-0;
    }
  }

  .ant-form-item {
    label {
      @apply leading-5 px-0 font-normal;
    }
  }
}